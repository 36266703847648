@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-color: #028383;
  --dark-color: #243434;
  --light-color: #f0f0f0;
}

body,
html {
  height: 100%;
  background: #243434;
}

.container-fluid {
  display: block;
  position: relative;
  margin: 40px auto;
  height: auto;
  padding: 20px;
}

.container-fluid .title {
  font-size: 3em;
  text-align: center;
  /* border: 5px solid var(--primary-color); */
  padding: 0.3em 0.2em;
  border-radius: 20px;
}


.text-light {
  color: var(--light-color);
}
.li1 {
  margin-left: -1px;
}
 .h3{
  font-size: 1.5em !important; 
}

.container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.container .questions {
  padding: 3em;
}

/* 
.container .questions .qid{
  padding: .2em .7em;
  color: #222222;
  background-color: #0DFF92;
  border-radius: 50px;
} */

.container-fluid .grid {
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.btn-1{
  margin-bottom: -5px;
}
.btn-1{
  background-color: #faff5a;
}
.btn-1:hover{
  background-color: white;
}
.container-fluid .btn {
  padding: 0.2em 1.7em;
  border: none;
  border-radius: 1em;
  font-size: 1.2em;
}

.container-fluid .btn:hover {
  cursor: pointer;
  background-color: #f0f0f0;
  color: #202020;
}

.next {
  background-color: #faff5a;
  justify-self: flex-end;
}

.prev {
  background-color: #faff5a;
  justify-self: flex-start;
}

ul li {
  color: #aaaaaa;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #a8a1a1;
  
}

ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
  
}

ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 15px 25px 25px 80px;
  /* margin: 10px auto; */
  height: 30px;
  z-index: 9;
  cursor: pointer;
  transition: all;
  -webkit-transition: all 0.25s linear;
}

ul li:hover label {
  color: #ffffff;
}

ul li .check {
  display: block;
  position: absolute;
  border: 5px solid #aaaaaa;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 20px;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

ul li:hover .checked {
  border: 5px solid #ffffff;
}
#form-pay{
  display: block;
  text-align: center;
}
#form-pay .user-id{
  padding: .7em 2em;
  width: 60%;
  border: none;
  border-radius: 3px;
  font-size: 1em;
  /* margin-right: 15px; */
}
#form-pay .password-id{
  padding: .7em 2em;
  width: 60%;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
#form-pay .email-id{
  padding: .7em 2em;
  width: 60%;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
  left: 5px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 5px solid var(--primary-color);
}

input[type="radio"]:checked ~ .check::before {
  background: var(--primary-color);
}

input[type="radio"]:checked ~ .text-primary {
  color: var(--primary-color);
}

/* To get selected option we are using this checked class */
.checked {
  border: 5px solid var(--primary-color) !important;
}

.checked::before {
  background: var(--primary-color);
}
#footer{
  color:rgb(97, 92, 92);
  font-size: .8rem;
  margin-top: 100px;
  text-align: center;
}