.container ol li{
    font-size: 1.4em;
    color: #cecece;
}

.start{
    display: flex;
    justify-content: center;
    padding-top: 2em;
}
.start .reg{
    margin-right: 10px;
}
.start .btn{
    padding: .2em 1.7em;
    border: none;
    border-radius: 50px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    font-size: 1.2em;
    color: #202020;
    text-decoration: none;
    background-color: #faff5a;
 
}

.btn:hover{
    transform: scale(1.05);
    transition: all 0.2s;
    
}
.btn:active {
    transform: scale(0.8); 
}

#form{
    display: flex;
    justify-content: center;
    margin-top: 4em;
}

#form .user-id{
    padding: .7em 2em;
    width: 50%;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    margin-right: 10px;
}
#form .password-id{
    padding: .7em 2em;
    width: 50%;
    border: none;
    border-radius: 3px;
    font-size: 1em;
}